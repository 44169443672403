@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.carousel-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 16px;
}

.carousel-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &.standard {
    justify-content: unset;
  }

  &.auto {
    justify-content: flex-start;
    align-items: stretch;
  }

  &.preview,
  &.preview-scroll {
    justify-content: flex-start;
    padding: 16px 0; // padding is required so shadow from PaperCarousel won't be cut off

    // Add extra space when using cards with badges
    &[data-with-badges] {
      padding: fn.spacing(6) 0 16px;
    }
  }

  &.preview-small {
    justify-content: flex-start;
  }

  &.preview-small-xs {
    justify-content: flex-start;
  }

  &.preview-stretch {
    justify-content: flex-start;
    align-items: stretch;
  }
}

.carousel-container::-webkit-scrollbar {
  display: none;
}

.carousel-container-item {
  flex-shrink: 0;
  scroll-snap-align: start;
  box-sizing: content-box;
}

.carousel-container-item.standard {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-container-item.auto {
  width: auto;
  height: 100%;
  display: flex;
  padding-left: 16px;
}

.carousel-container-item.auto:last-child {
  padding-right: 16px;
}

.carousel-container-item.preview-full {
  width: 100%;
  margin: 0 var.$spacing;
}

.carousel-container-item.preview {
  width: 85%;
  margin: 0 var.$spacing;
}

.carousel-container-item.preview-small {
  width: 80%;
  margin: 0 var.$spacing;
}

.carousel-container-item.preview-small-xs {
  width: 80%;
  margin: 0 var.$spacing;
}

.carousel-container-item.preview-stretch {
  width: 80%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.carousel-container-item.preview-scroll {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}

.carousel-control {
  display: none;
}

.carousel-button-container .carousel-control {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var.$color-background-primary;
  box-shadow: 0 2px 9px -2px var.$color-neutral-75, 0 2px 3px -2px #c0cbd8;

  svg {
    color: #30f;
  }
}

.carousel-button-container button:hover {
  background-color: var.$color-blue-100 !important;
}

.control-left {
  left: 1em;
}

.control-left-center {
  left: calc(50% - 450px);
}

.control-right {
  right: 1em;
}

.control-right-center {
  right: calc(50% - 450px);
}

[data-btn-carousel-touched='true'] .carousel-control {
  background-color: #fff;
}

.preview-scroll div[data-paper] {
  min-height: fn.spacing(70);
}

.hidden {
  display: none;
}

@media screen and (min-width: var.$breakpoint-sm) {
  .carousel-container-item.preview-scroll {
    width: 80%;
  }
}

/* iPad portrait or similar */
@media screen and (min-width: 768px) {
  .carousel-container-item.preview {
    width: 65%;
  }

  .carousel-container-item.preview-small {
    width: 32%;
  }

  .carousel-container-item.preview-small-xs {
    width: 90%;
  }

  .carousel-container-item.preview-stretch {
    width: 32%;
  }

  .carousel-container-item.preview-scroll {
    width: 60%;
  }

  .carousel-container-item.auto {
    padding-left: 32px;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .no-right-padding {
    padding-right: 0;
  }

  .carousel-wrapper {
    padding: 0;
  }

  .carousel-wrapper.preview {
    padding-left: 96px;
  }

  .carousel-wrapper.preview-small {
    padding-left: 0;
  }

  .carousel-wrapper.preview-small-xs {
    padding-left: 0;
  }

  .carousel-wrapper.standard {
    padding-left: 24px;
    padding-right: 24px;
  }

  .carousel-wrapper.preview-stretch {
    padding-left: 0;
  }

  .carousel-wrapper.preview-scroll {
    padding-left: 0;
  }

  .dot-controls {
    margin-top: 24px;
  }

  .carousel-container {
    align-items: center;
  }

  .carousel-control {
    text-align: center;
    position: absolute;
    z-index: 2;
    top: calc(50% - 1em);
    display: inline-flex;
    align-items: center;
  }

  .carousel-container-item.preview {
    width: 72%;
    margin: 0 16px;

    &:last-child {
      margin-right: fn.spacing(19);
    }
  }

  .carousel-container-item.preview-small {
    width: 30%;
    margin: 0 24px;

    &:last-child {
      margin-right: fn.spacing(19);
    }

    &:first-child {
      padding-left: fn.spacing(11);
    }
  }

  .carousel-container-item.preview-small-xs {
    width: 30%;
    margin: 0 24px;

    &:last-child {
      margin-right: fn.spacing(19);
    }

    &:first-child {
      padding-left: fn.spacing(11);
    }
  }

  .carousel-container-item.preview-stretch {
    width: 30%;
    margin: 0;

    &:first-child {
      padding-left: fn.spacing(11);
    }
  }

  .carousel-container-item.preview-scroll {
    width: 40%;
    margin: 0;

    &:first-child {
      padding-left: fn.spacing(11);
    }
  }

  .carousel-container-item.auto:first-child {
    padding-left: fn.spacing(13);
  }

  .carousel-container-item.auto:last-child {
    padding-right: 16px;
  }
}
@media screen and (min-width: var.$breakpoint-xl) {
  .carousel-container-item.preview {
    width: 70%;
  }

  .carousel-container-item.preview-small {
    width: 27.5%;
  }

  .carousel-container-item.preview-small-xs {
    width: 27.5%;
  }

  .carousel-container-item.preview-stretch {
    width: 27.5%;
  }

  .carousel-container-item.preview-scroll {
    width: 80%;
  }
}

@media screen and (max-width: var.$breakpoint-xl) {
  .carousel-container-item.preview-small div {
    background-color: transparent;
  }

  .carousel-container-item.preview-small-xs div {
    background-color: transparent;
  }
}
