@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.title-divider {
  margin-bottom: 32px;
}

.title {
  text-align: center;

  :not(:last-child) {
    padding-bottom: 32px;
  }
}

.subtitle {
  text-align: center;
  margin: 16px 0 0;
}

//Desktop
@media only screen and (min-width: 1024px) {
  .title-divider {
    margin-bottom: 64px;
  }
}
