@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/images' as img;

.section {
  background-color: var.$color-blue-50;
  padding-top: 32px;
  padding-bottom: 32px;
}

.indicators > div {
  background-color: #c0cbd8;
}

.indicators {
  padding-top: 32px;
}

.indicators > .active-indicator {
  background-color: #30f;
  opacity: 1;
}

.paper {
  border: 1px solid #eff2f8;
  border-radius: var.$achieve-default-border-radius;
  padding: fn.spacing(5);
}

.paper-carousel-title-divider {
  margin-bottom: 0;
}

.image {
  border-radius: 12px;
}

.grid-item-image span {
  @include img.img-span;
}

.grid-item-image img {
  @include img.img-element;
}

.img-container {
  @include img.img-container;

  width: 100%;
  height: 341px;
}

.card-text-grid {
  padding-left: fn.spacing(5);
  padding-right: fn.spacing(5);
  text-align: center;
}

.lead-text {
  color: #5c708a;
  margin-bottom: 8px;
}

.heading-text {
  color: #1d252f;
  display: inline;
}

.supporting-text {
  color: var.$color-neutral-900;
  margin: 16px 0;
}

.title-link {
  text-decoration: none;
}

.title-link:hover {
  text-decoration: underline;
  text-decoration-color: #1d252f;
}

.link {
  text-decoration: none;

  p {
    margin: 0;
  }
}

.shadow-selected .selected-item .paper {
  box-shadow: 0 3.3px 18px -3px rgb(16 24 40 / 9%), 0 5px 6px -2px rgb(16 24 40 / 4%);
}

.grid-item-image {
  width: inherit;
  height: inherit;
  padding: 0 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: 1024px) {
  .outer-grid-container {
    height: 339px;
  }

  .grid-item-image {
    padding: 0 32px 0 0;
    width: calc(100% * 7 / 12);
  }

  .card-text-grid {
    padding-left: 0;
    padding-right: 0;
    text-align: left;
  }

  .section {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .paper-carousel-title-divider {
    margin-bottom: 32px;
  }

  .lead-text {
    color: #42546b;
    margin-bottom: 16px;
  }

  .supporting-text {
    color: #1d252f;
    margin: 24px 0 16px;
  }
}
